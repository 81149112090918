import React from 'react'
import WideNav from '../../navbars/wide-nav/WideNav'
import './PurePlayer.scoped.scss'

export default class PurePlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="content">
        <section id="intro" class="w3-animate-left">
          <WideNav navigationDepth={2} />
          <div class="header">
            <h1 class="title">Pure Music Player</h1>
            <div class="portfolio-lang">
              <div class="lang vb">❏ Visual Basic</div>
            </div>
          </div>
        </section>
        <section id="desc" class="container">
          <h2>What is it?</h2>
          <p>
            Pure music player, is a desktop application that lets you organise
            and play music files on your computer. It does this by importing
            them into its own library, where it stored extra metadata for the
            files including track info and album covers. It also allows you to
            create playlists of your music, and automatically collates tracks in
            the same album.
          </p>
          <h2>What does it run on?</h2>
          <p>
            Pure Player is designed for Windows. It may also run on Mac and/or
            Unix/Linux systems. I cannot confirm this as I do not have such
            systems to test with. You can still try the application. If you know
            how to make a compatible version (presuming this is necessary), the
            source link is provided below.
          </p>
          <a
            href="https://github.com/DeveloperRic/PureMusicPlayer"
            target="_blank"
            rel="noreferrer"
          >
            View the Pure Music Player GitHub repository
          </a>
          <h2>How can I get it?</h2>
          <p>
            An link to the application executable can be downloaded from the
            link below. Don't worry, it has no viruses or malware running. If
            your browser shows a dialog upon clicking the link, just click on
            accept and
            <b>place the executable in its own folder.</b>
          </p>
          <a href="/projects/pureplayer/PureMusicPlayer.exe">
            Click here to download Pure Music Player
          </a>
        </section>
      </div>
    )
  }
}
