import React from 'react'
import WideNav from '../../navbars/wide-nav/WideNav'
import './Timothy.scoped.scss'

export default class Timothy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="content">
        <section id="intro" class="w3-animate-left">
          <WideNav navigationDepth={2} />
          <div class="header">
            <h1 class="title">Timothy Twitter Automator</h1>
            <div class="portfolio-lang">
              <div class="lang javafx">❏ JavaFX</div>
            </div>
          </div>
        </section>
        <section id="desc" class="container">
          <h2>What is it?</h2>
          <p>
            Timothy, is a bot that aims to help automate minor and repetitive
            tasks. Currently, timothy can respond to the following triggers:
          </p>
          <ul>
            <li>User Follow</li>
            <li>User UnFollow</li>
            <li>Mention</li>
            <li>Direct Message</li>
          </ul>
          <p>
            Timothy can then respond with one or more actions (depending on
            compatibility):
          </p>
          <ul>
            <li>New Tweet</li>
            <li>Message the person</li>
            <li>Follow back</li>
            <li>UnFollow</li>
            <li>Reply to tweet</li>
            <li>Reply direct message</li>
            <li>Like tweet/quote</li>
            <li>Retweet tweet/quote</li>
            <li>Quote tweet/quote</li>
          </ul>
          <h2>What does it run on?</h2>
          <p>
            Timothy is a JavaFX Application. Meaning that it will run on almost
            every device that is capable of user input, secondary storage and an
            internet connection.
          </p>
          <p>
            Timothy was built using the JTwitter Library for the Twitter API.
          </p>
          <a
            href="https://github.com/DeveloperRic/TimothyTwitterBot"
            target="_blank"
            rel="noreferrer"
          >
            View Timothy's GitHub reprository
          </a>
          <br />
          <a
            href="https://www.winterwell.com/software/jtwitter.php"
            target="_blank"
            rel="noreferrer"
          >
            View the JTwitter Library
          </a>
          <h2>How can I get it?</h2>
          <p>
            A link to the JavaFX Application ZIP can be downloaded from the link
            below. Don't worry, it has no viruses or malware running. If your
            browser shows a dialog upon clicking the link, just click on accept.
          </p>
          <a href="/projects/timothy/Timothy.zip">Click here to download Timothy</a>
        </section>
      </div>
    )
  }
}
