import React from 'react'
import { Link } from 'react-router-dom'
import './WideNav.scss'

export default class WideNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="wide-nav col-sm-6 col-md-4 background-secondary drop-shadow w3-animate-left">
        <div>
          <Link to="/">Home</Link>
          <hr />
        </div>
        <div>
          <Link to="/projects">Projects</Link>
          <hr />
        </div>
        <div>
          <a href="/#whyicode">Why I code</a>
          <hr />
        </div>
        <div>
          <a href="mailto:victor.n.olaitan@gmail.com?subject=Website%20Contact%20Form&body=Name:%20%0AContact%20Info:%20%0A%0AMessage:%20%0A">
            Contact me
          </a>
        </div>
      </div>
    )
  }
}
