import React from 'react'
import WideNav from '../../navbars/wide-nav/WideNav'
import './Scholar.scoped.scss'

export default class Scholar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="content">
        <section id="intro" class="w3-animate-left">
          <WideNav navigationDepth={2} />
          <div class="header">
            <h1 class="title">Scholar</h1>
            <div class="portfolio-lang">
              <div class="lang angular">❏ Angular</div>
              <div class="lang mongodb">❏ MongoDB</div>
              <div class="lang typescript">❏ TypeScript</div>
            </div>
          </div>
        </section>
        <section id="desc" class="container">
          <h2>What is it?</h2>
          <p>
            Scholar is a Progressive Web Application built with Angular and is
            being deployed to the Google Play Store as a&nbsp;
            <a
              href="https://developer.chrome.com/docs/android/trusted-web-activity/"
              target="_blank"
              rel="noreferrer"
            >
              Trusted Web Activity (TWA)
            </a>
            . The goal of the app is to benefit students by giving them a tool
            through which they can track their classes, assignments, exams, etc.
            and also be able to keep a close eye on their current & projected
            GPA.
          </p>
          <h2>What does it run on?</h2>
          <p>
            Scholar heavily utilizes serverless tools within MongoDB Realm &
            Netlify including cloud functions, triggers, and hosting; it also
            utilizes Identity-as-a-service from Auth0 for authentication.
            Scholar takes a mobile-first approach to its interface so as to
            enhance the mobile user experience.
          </p>
          <h2>How can I get it?</h2>
          <a
            href="https://github.com/DeveloperRic/Scholar"
            target="_blank"
            rel="noreferrer"
          >
            View Scholar's GitHub reprository
          </a>
          <p>Scholar is currently in closed beta.</p>
          <p>
            All rights to code & content are reserved, and are only made visible
            for educational purposes.
          </p>
        </section>
      </div>
    )
  }
}
