import React from 'react'
import { Link } from 'react-router-dom'
import WideNav from '../navbars/wide-nav/WideNav'

export default class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      languagesStatus: 'more',
    }
  }

  render() {
    return (
      <div className="content">
        <section id="intro" className="w3-animate-left">
          <WideNav navigationDepth={1} />
          <div>
            <h1 className="title">My Projects</h1>
            <div className="portfolio-lang">
              <div className="lang android">|A| Android</div>
              <div className="lang angular">🅰 Angular</div>
              <div className="lang express">❏ Express</div>
              <div className="lang java">❏ Java</div>
              <div className="lang javafx">❏ JavaFX</div>
              <div className="lang javascript">|JS| JavaScript</div>
              <div className="lang mongodb">❏ MongoDB</div>
              <div className="lang node">❏ Node.js</div>
              <div className="lang react">⚛ React.js</div>
              <div className="lang typescript">|TS| TypeScript</div>
              <div className="lang vb">❏ Visual Basic</div>
            </div>
          </div>
        </section>
        <section id="portfolio" className="text-center w3-animate-opacity">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4 col-sm-6 portfolio-item no-url">
                <div>
                  <div className="portfolio-caption">
                    <h4>
                      This Website
                      <span className="lang">
                        <span className="react">⚛</span>
                        <span className="javascript">|JS|</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      As in victorolaitan.xyz of course :)
                      <br /> ... since May 2022
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./scholar">
                  <div className="portfolio-caption">
                    <h4>
                      Scholar
                      <span className="lang beta">beta</span>
                      <span className="lang">
                        <span className="angular">🅰</span>
                        <span className="mongodb">❏</span>
                        <span className="typescript">|TS|</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      A Progressive Web App (PWA) used to keep a close eye on
                      your classes, assignments, and grade progression.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./binder">
                  <div className="portfolio-caption">
                    <h4>
                      Binder
                      <span className="lang beta">beta</span>
                      <span className="lang">
                        <span className="angular">🅰</span>
                        <span className="express">❏</span>
                        <span className="javascript">|JS|</span>
                        <span className="node">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      A cloud based web app to help consolidate all your files
                      from cloud based storage services.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./pureplayer">
                  <div className="portfolio-caption">
                    <h4>
                      Pure Music Player
                      <span className="lang">
                        <span className="vb">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      Pure is a desktop application that lets you organise and
                      play music from your PC.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./draftpad">
                  <div className="portfolio-caption">
                    <h4>
                      Draftpad
                      <span className="lang">
                        <span className="android">|A|</span>
                        <span className="java">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      An Android app for creating and editing notes in the most
                      simple manner possible.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./timothy">
                  <div className="portfolio-caption">
                    <h4>
                      Timothy Twitter Automator
                      <span className="lang">
                        <span className="javafx">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      Timothy lets you automate actions when an event occurs
                      regaring your twitter account.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item no-url">
                <div>
                  <div className="portfolio-caption">
                    <h4>
                      FeedMe
                      <span className="lang alpha">alpha</span>
                      <span className="lang">
                        <span className="android">|A|</span>
                        <span className="java">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      An Android app where guests of an event can reserve the
                      meals/drinks that they like.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./tweetit">
                  <div className="portfolio-caption">
                    <h4>
                      TweetIt
                      <span className="lang">
                        <span className="java">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      A twitter wrapper for the Spigot API. Removes repetitive
                      tasks and shares data across plugins.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <a href="https://github.com/DeveloperRic">
                  <div className="portfolio-caption">
                    <h4 className="lang">
                      <span className="android">.</span>
                      <span className="java">.</span>
                      <span className="vb">.</span>
                    </h4>
                    <p className="text-muted">GitHub</p>
                    <p className="text-primary link-hover">
                      View my open sourced projects on GitHub.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
