import React from 'react'
import { Game, GridSquare } from '.'
import './TicTacToe.scoped.scss'

export default class TicTacToe extends React.Component {
  constructor(props) {
    super(props)
    this.game = new Game([])

    for (let i = 0; i < 3; ++i) {
      const row = []
      for (let j = 0; j < 3; ++j) {
        row.push(
          new GridSquare(
            () => this.game._getCharToPlay(),
            newChar => this.game._onSquareChanged(newChar)
          )
        )
      }
      this.game.grid.push(row)
    }
  }

  render() {
    return (
      <div id="tic-tac-toe-game">
        <TicTacToeRow game={this.game} squares={this.game.grid[0]} />
        <TicTacToeRow game={this.game} squares={this.game.grid[1]} />
        <TicTacToeRow game={this.game} squares={this.game.grid[2]} />
      </div>
    )
  }
}

class TicTacToeRow extends React.Component {
  render() {
    return (
      <div className="tic-row">
        <TicTacToeSquare
          game={this.props.game}
          square={this.props.squares[0]}
        />
        <TicTacToeSquare
          game={this.props.game}
          square={this.props.squares[1]}
        />
        <TicTacToeSquare
          game={this.props.game}
          square={this.props.squares[2]}
        />
      </div>
    )
  }
}

class TicTacToeSquare extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
    }
  }

  componentDidMount() {
    this.props.square.observer = next => {
      this.setState({ text: next == null ? '' : next })
    }
  }

  componentWillUnmount() {
    this.props.square.unsubscribe()
  }

  onClick = () => {
    if (!this.props.game.inProgress) {
      if (window.confirm('Start game?')) {
        this.props.game.reset(true)
      }
      return
    }
    if (this.state.text === '') {
      this.props.square.play(this.props.square.getCharToPlay())
    }
  }

  render() {
    return (
      <div className="tic-square" onClick={this.onClick}>
        {this.state.text}
      </div>
    )
  }
}
