import React from 'react'
import WideNav from '../../navbars/wide-nav/WideNav'
import './Draftpad.scoped.scss'

export default class Draftpad extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="content">
        <section id="intro" class="w3-animate-left">
          <WideNav navigationDepth={2} />
          <div class="header">
            <h1 class="title">Draftpad</h1>
            <div class="portfolio-lang">
              <div class="lang android">❏ Android</div>
              <div class="lang java">❏ Java</div>
            </div>
            <a
              class="play-badge"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.rictacius.draftpad&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              rel="noreferrer"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
              />
            </a>
          </div>
        </section>
        <section id="desc" class="container">
          <h2>What is it?</h2>
          <p>
            Draftpad is an andriod app that lets you make short notes, quickly.
            It has a simple interface that makes it easy to just open and jot
            down any spontaneous idea that you have.
          </p>
          <h2>What does it run on?</h2>
          <p>
            Draftpad is built targeting the latest Android version, however it's
            minimum compatability requirement is Android 4.4+.
          </p>
          <p>Unfortunately if you have an iPhone, you cannot run Draftpad.</p>
          <h2>How can I get it?</h2>
          <a
            href="https://github.com/DeveloperRic/Draftpad"
            target="_blank"
            rel="noreferrer"
          >
            View Draftpad's GitHub reprository
          </a>
          <p>
            I have released Draftpad to the Google Play Store. If you own an
            Android phone, please download it. I will continue to update it with
            more features.
          </p>
          <a
            href="https://play.google.com/store/apps/details?id=com.rictacius.draftpad"
            target="_blank"
            rel="noreferrer"
          >
            Download Draftpad from the Play Store
          </a>
        </section>
      </div>
    )
  }
}
