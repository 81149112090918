import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Binder from './binder/Binder'
import Draftpad from './draftpad/Draftpad'
import Projects from './Projects'
import PurePlayer from './pureplayer/PurePlayer'
import Scholar from './scholar/Scholar'
import Timothy from './timothy/Timothy'
import TweetIt from './tweetit/TweetIt'
import './ProjectsIndex.scss'

export default class ProjectsIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      languagesStatus: 'more',
    }
  }

  render() {
    return (
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path="/binder" element={<Binder />} />
        <Route path="/draftpad" element={<Draftpad />} />
        <Route path="/pureplayer" element={<PurePlayer />} />
        <Route path="/scholar" element={<Scholar />} />
        <Route path="/timothy" element={<Timothy />} />
        <Route path="/tweetit" element={<TweetIt />} />
      </Routes>
    )
  }
}
