import React from 'react'
import WideNav from '../../navbars/wide-nav/WideNav'
import './Binder.scoped.scss'

export default class Binder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="content">
        <section id="intro" class="w3-animate-left">
          <WideNav navigationDepth={2} />
          <div class="header">
            <h1 class="title">Binder</h1>
            <div class="portfolio-lang">
              <span class="lang angular">❏ Angular</span>
              <span class="lang express">❏ Express</span>
              <span class="lang javascript">❏ JavaScript</span>
              <span class="lang node">❏ Node.js</span>
            </div>
          </div>
        </section>
        <section id="desc" class="container">
          <h2>What is it?</h2>
          <p>
            Binder is a web app made up of a Node.js server backend and an
            AngularJS frontend. Its purpose is to create a single location where
            you can view files from different storage providers. It is not a
            provider in itself, it's just a window to other providers.
          </p>
          <h2>What does it run on?</h2>
          <p>
            The server backend runs on a Google Cloud hosted instance. Whilst
            the frontend is requested and run on any compatible web browser.
          </p>
          <h2>How can I get it?</h2>
          <a
            href="https://github.com/DeveloperRic/binder"
            target="_blank"
            rel="noreferrer"
          >
            View Binder's GitHub reprository
          </a>
          <p>
            You can visit binder online with&nbsp;
            <a href="https://binderapp.xyz">this link</a>.
          </p>
        </section>
      </div>
    )
  }
}
