import React from 'react'
import { Link } from 'react-router-dom'
import TicTacToe from '../tic-tac-toe/TicTacToe'
import WideNav from '../navbars/wide-nav/WideNav'
import profilePic from './profile.jpg'
import './Home.scss'

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      languagesStatus: 'more',
    }
  }

  showMoreLanguages = () => {
    this.setState()
    document.getElementById('portfolio-lang-more').classList.toggle('hide')
    this.setState(state => ({
      languagesStatus: state.languagesStatus === 'more' ? 'less' : 'more',
    }))
  }

  render() {
    return (
      <div className="content">
        <section id="intro" className="container">
          <div className="row">
            <WideNav navigationDepth={0} />
            <div className="profile col-sm-6 col-md-4">
              <img className="circle-img" src={profilePic} alt="Me" />
            </div>
            <div className="col-sm-12 col-md-4">
              <h1 className="title">Hello there! 👋🏿</h1>
              <p>
                I'm a software engineer with a Bachelor of Computer Science from
                Carleton University.
              </p>
              <p>This is where I keep my stuff.</p>

              <p>
                Below, you will find my portfolio with access to projects I have
                worked on (or am currently working on).
              </p>
            </div>
          </div>
        </section>
        <section id="portfolio" className="text-center">
          <div className="container">
            <div className="row">
              <h2>Projects</h2>
            </div>
            <div className="row portfolio-lang">
              <div className="lang angular">❏ Angular</div>
              <div className="lang android">❏ Android</div>
              <div className="lang java">❏ Java</div>
              <div className="lang node">❏ Node.js</div>
              <div id="portfolio-lang-more" className="hide">
                <div className="lang express">❏ Express</div>
                <div className="lang javascript">❏ JavaScript</div>
                <div className="lang mongodb">❏ MongoDB</div>
                <div className="lang typescript">❏ TypeScript</div>
                <div className="lang vb">❏ Visual Basic</div>
              </div>
              <div
                className="lang portfolio-lang-more"
                onClick={this.showMoreLanguages}
              >
                <i className="large material-icons">more_horiz</i>
                <span className="portfolio-lang-more-text">
                  {this.state.languagesStatus}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./projects/scholar">
                  <div className="portfolio-caption">
                    <h4>
                      Scholar
                      <span className="lang">
                        <span className="angular">❏</span>
                        <span className="mongodb">❏</span>
                        <span className="typescript">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      A Progressive Web App (PWA) used to keep a close eye on
                      your classes, assignments, and grade progression.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./projects/binder">
                  <div className="portfolio-caption">
                    <h4>
                      Binder
                      <span className="lang">
                        <span className="angular">❏</span>
                        <span className="express">❏</span>
                        <span className="javascript">❏</span>
                        <span className="node">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      A cloud based web app to help consolidate all your files
                      from cloud based storage services.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./projects/draftpad">
                  <div className="portfolio-caption">
                    <h4>
                      Draftpad
                      <span className="lang">
                        <span className="lang android">❏</span>
                        <span className="java">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      An Android app for creating and editing notes in the most
                      simple manner possible.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./projects/pureplayer">
                  <div className="portfolio-caption">
                    <h4>
                      Pure Music Player
                      <span className="lang">
                        <span className="vb">❏</span>
                      </span>
                    </h4>
                    <p className="text-muted">
                      Pure is a desktop application that lets you organise and
                      play music from your PC.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <a href="https://github.com/DeveloperRic">
                  <div className="portfolio-caption">
                    <h4>
                      <span className="android">.</span>{' '}
                      <span className="java">.</span>
                      <span className="vb">.</span>
                    </h4>
                    <p className="text-muted">GitHub</p>
                    <p className="text-primary link-hover">
                      View my open-sourced projects on GitHub.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 portfolio-item">
                <Link to="./projects">
                  <div className="portfolio-caption">
                    <h4>
                      <span className="android">.</span>{' '}
                      <span className="java">.</span>
                      <span className="vb">.</span>
                    </h4>
                    <p className="text-muted">Where's the rest?</p>
                    <p className="text-primary link-hover">
                      Click to see an in-depth view of all my projects.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section id="whyicode">
          <div className="container">
            <div className="row text-center">
              <h2>Why I Code</h2>
              <p>There are three basic reasons why I code...</p>
            </div>
            <br />
            <h4>1. My Personality 🙈</h4>
            <p>
              I believe my personality plays a great role in my obsession with
              programming. Being increasingly curious, introverted, and easily
              inspired&mdash;these and more are what I believe make programming
              such a perfect fit for me, and me for it.
            </p>
            <h4>2. My Hobbies 🏖</h4>
            <p>
              I'm not going to bore you with all the cliché "I wanted to do this
              since I was 5 years old", because we all know that's not true.
              However, I believe my obsession with the computer falls as far
              back since my time in primary school, where I would always want to
              see how the computer did the things I wanted it to do. It became
              even more apparent when I realised that there are tiny computers
              in many electronics, I began to take every electronic device I
              could find appart, just to see what's inside.
            </p>
            <h4>3. My Teaching 📚</h4>
            <p>
              Although I had obsessed with the computer since primary school, I
              never tried programming officially until GCSE year 10. I started
              programming during Computing class, and went on to do more out of
              school.
            </p>
            <p>
              My first programming languages were a low-level language (
              <a
                href="https://peterhigginson.co.uk/LMC/"
                target="_blank"
                rel="noreferrer"
              >
                The LMC
              </a>
              ) and two high-level languages:&nbsp;
              <a
                href="https://scratch.mit.edu/"
                target="_blank"
                rel="noreferrer"
              >
                Scratch
              </a>{' '}
              and JavaScript.
            </p>
            <p>
              Later, in my A Level (GCE) education, I was taught three new
              high-level programming languages;&nbsp;
              <a
                href="https://docs.microsoft.com/en-gb/dotnet/visual-basic/"
                target="_blank"
                rel="noreferrer"
              >
                Visual Basic
              </a>
              , SQL, and a little bit of&nbsp;
              <a
                href="http://www.swi-prolog.org/"
                target="_blank"
                rel="noreferrer"
              >
                Prolog
              </a>
              . However, I did not restrict myself to these languages alone, I
              alse decided to teach myself&nbsp;
              <a
                href="https://www.java.com/en/"
                target="_blank"
                rel="noreferrer"
              >
                Java
              </a>{' '}
              along with HTML and CSS.
            </p>
            <br />
            <p>
              Now, at university (and at work 😉), I've been exposed to so many
              languages & frameworks. There's so much to choose from!
            </p>
          </div>
        </section>
        <section id="tic-tac-toe">
          <div className="container">
            <div className="row text-center">
              <h2>Bored? Play some Tic-Tac-Toe</h2>
              <TicTacToe />
            </div>
          </div>
        </section>
      </div>
    )
  }
}
