import React from 'react'
import { Link } from 'react-router-dom'
import './TitleBar.scss'

export default class TitleBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleSmallNav = () => {
    document.getElementById('small-nav').classList.toggle('show')
    document.getElementById('small-nav-open').classList.toggle('hide')
    document.getElementById('small-nav-close').classList.toggle('hide')
  }

  render() {
    return (
      <div
        id="title-bar"
        className="no-opacity navbar navbar-fixed-top text-primary w3-animate-opacity"
      >
        <div className="logo">Victor Olaitan</div>
        <div
          className="small-nav-btn w3-animate-opacity"
          onClick={this.toggleSmallNav}
        >
          <i id="small-nav-open" className="large material-icons">
            menu
          </i>
          <i id="small-nav-close" className="hide large material-icons">
            close
          </i>
          <h2 className="small-nav-btn-text w3-animate-opacity">Menu</h2>
        </div>
        <br />
        <div id="small-nav" className="w3-animate-top">
          <div>
            <Link to="/">Home</Link>
            <hr />
          </div>
          <div>
            <Link to="/projects">Projects</Link>
            <hr />
          </div>
          <div>
            <a href="/#whyicode">Why I code</a>
            <hr />
          </div>
          <div>
            <a href="mailto:victor.n.olaitan@gmail.com?subject=Website%20Contact%20Form&body=Name:%20%0AContact%20Info:%20%0A%0AMessage:%20%0A">
              Contact me
            </a>
          </div>
        </div>
      </div>
    )
  }
}
