import React from 'react'
import WideNav from '../../navbars/wide-nav/WideNav'

export default class TweetIt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="content">
        <section id="intro" class="w3-animate-left">
          <WideNav navigationDepth={2} />
          <div>
            <h1 class="title">TweetIt (for Minecraft)</h1>
            <div class="portfolio-lang">
              <div class="lang java">❏ Java</div>
            </div>
          </div>
        </section>
        <section id="desc" class="container">
          <h2>What is it?</h2>
          <p>TweetIt allows you to manage player's twitter accounts in-game.</p>
          <p>
            TweetIt manages the authentication for you. All user related data is
            encrypted&nbsp;
            <b>before</b> storage, so you know it's safe.
          </p>
          <p>
            I am thinking about adding a limiter, to allow server owners as well
            as players to directly restrict what a TweetIt dependant plugin can
            do on their account, rather than have Twitter do it for them.
          </p>
          <h2>What does it run on?</h2>
          <p>
            TweetIt is a SpigotMC plugin. It will only run when used in
            conjuction with the SpigotMC mod built for Minecraft servers.
          </p>
          <a
            href="https://github.com/DeveloperRic/TweetIt"
            target="_blank"
            rel="noreferrer"
          >
            View the TweetIt GitHub reprository
          </a>
          <h2>How can I get it?</h2>
          <p>
            The plugin discription can be found on its SpigotMC resource page.
            If you want to skip all that, you can download the plugin JAR from
            the link below.
          </p>
          <a
            href="https://www.spigotmc.org/resources/tweetit.28848/"
            target="_blank"
            rel="noreferrer"
          >
            View its SpigotMC resource page
          </a>
          <br />
          <a href="/projects/tweetit/TweetIt.jar">Click here to download TweetIt</a>
        </section>
      </div>
    )
  }
}
